export enum Flags {
  ACCOUNT_COMP_COHORT_AUG_2023 = 'accountCompCohortAug2023',
  ACCOUNT_SETTINGS = 'olympusAccountSettingsNav',
  ACCOUNT_USAGE_NOTIFICATIONS_CONFIG_PANEL = 'accountUsageNotificationsConfigPanel',
  AUTO_SUGGEST_OWNER = 'autoSuggestOwner',
  CSV_REPORT_SECTION = 'asyncCsvSection',
  DISPLAY_SNOOZE = 'displaySnoozeV1',
  FULLSTORYNEW = 'fullStoryNew',
  FILTER_SNOOZE = 'filterSnooze',
  GROUPING_RULES_APPLIED_CONTROL = 'groupingRulesAppliedControl',
  IMPROVE = 'improve',
  IMPROVE_LIMIT_PROJECTS = 'improveLimitProjects',
  INKEEPCHAT = 'inkeepChat',
  ITEM_LIST_SUBSCRIPTIONS_ENDPOINT = 'itemListSubscriptionsGraphqlEndpoint',
  MERGE_ITEM = 'mergeItem',
  ITEM_SUMMARIZATION = 'itemSummarization',
  MTTR_TIME_SERIES = 'mttrTimeSeries',
  OLYMPUS_ACCESS_TOKENS_SETTINGS = 'olympusSettingsAccessTokens',
  OLYMPUS_AUDIT_LOG_SETTINGS = 'olympusSettingsAuditLog',
  OLYMPUS_BILLING_INFO_SETTINGS = 'olympusSettingsBillingInfo',
  OLYMPUS_GENERAL_SETTINGS = 'olympusSettingsGeneral',
  OLYMPUS_IDENTITY_PROVIDER_SETTINGS = 'olympusSettingsIdentityProvider',
  OLYMPUS_PEOPLE_NAV = 'olympusPeopleNav',
  OLYMPUS_PLAN_SETTINGS = 'olympusSettingsYourPlan',
  OLYMPUS_TEAMS_SETTINGS = 'olympusSettingsTeams',
  OLYMPUS_USERS_SETTINGS = 'olympusSettingsUsers',
  ONBOARDING_UPDATE = 'onboardingUpdate',
  PROJECT_DUPLICATION = 'projectDuplication',
  PROJECT_SETTINGS_LINKS = 'olympusProjectSettingsNavLinks',
  RQL_AI_ASSISTANT = 'rqlAiAssistant',
  SAVED_VIEWS = 'savedViews',
  SELECT_ALL_ITEMS_FOR_UPDATE = 'olympusSelectAllUpdate',
  SHOW_TYPICALITY = 'showTypicality',
  SOURCE_MAP_ERROR_DETAILS = 'olympusItemDetailsSourceMapErrors',
  SPEND_BUDGET = 'spendBudget',
  TEAM_ASSIGNMENT = 'teamAssignment',
  UPSELL_COMPONENT_SNOOZE_SELECTOR = 'upsellComponentSnoozeSelector',
  USAGE_GRAPHS = 'usageGraphs',
  USER_SETTINGS = 'olympusUserSettingsPages',
  WELCOME_DASHBOARD = 'welcomePage',
  ZENDESKCHAT = 'zendeskChat',
  ACCOUNT_DASHBOARD_USE_SERVER_CONFIG = 'accountDashboardUseServerConfig',
  SUNSET_HIPCHAT_LIGHTSTEP = 'sunsetHipchatLightstep',
}
