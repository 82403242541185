import { FunctionComponent } from 'react';

import { CurrentPlanCard } from '@/components/common/CurrentPlanCard';
import Modal from '@/rollbar-ui/components/Modal/Modal';

import FeatureSpotlightCard from './FeatureSpotlightCard';

type Props = {
  onClose: () => void;
};

const UpsellModal: FunctionComponent<Props> = ({ onClose }) => (
  <Modal hasCloseButton={true} isOpen={true} onClose={onClose} title=" ">
    <div className="flex flex-col gap-y-6">
      <FeatureSpotlightCard />

      <div className="grid grid-cols-2">
        <CurrentPlanCard />
      </div>
    </div>
  </Modal>
);

export { UpsellModal };
