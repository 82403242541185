import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { FunctionComponent, useEffect, useState } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { getFeaturesByPlan, getPlanById, FeatureByPlan } from '@/lib/utils';

const CurrentPlanCard: FunctionComponent = () => {
  const [features, setFeatures] = useState<FeatureByPlan[]>();

  const accountInfo = useAccountInfo();

  useEffect(() => {
    const { planId } = accountInfo;

    const plan = getPlanById({ id: planId });

    if (plan?.type) {
      const topFilteredFeatures = getFeaturesByPlan({ type: plan.type })
        ?.filter(({ value }) => value)
        .slice(0, 5);

      const filteredFeatures = topFilteredFeatures.filter(
        ({ name }) => name !== 'Included event volume'
      );

      const occurences = parseInt(String(plan?.includedOccurrences), 10) / 1000;

      setFeatures([
        {
          name: `${occurences}K occurrences per month`,
        },
        ...filteredFeatures,
      ]);
    }
  }, [accountInfo]);

  return (
    <div className="border-2 border-gray-200 p-6 rounded-lg flex flex-col gap-4">
      <h2 className="font-bold text-gray-700 text-2xl">Free</h2>

      <p className="text-gray-500 text-sm">
        Receive real-time alerts & keep track of issues
      </p>

      <p className="text-center text-gray-500 text-sm">Your current plan</p>

      <ol className="flex flex-col list-image-none list-none gap-y-4">
        {features?.map(({ name }) => (
          <li
            className="grid gap-x-2 items-center list-image-none list-none text-gray-500 text-sm grid-cols-[20px_1fr]"
            key={name}
          >
            <CheckCircleIcon />

            {name}
          </li>
        ))}
      </ol>
    </div>
  );
};

export { CurrentPlanCard };
