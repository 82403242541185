import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

const SHA_REGEX = /^[a-f0-9]{7,40}[.]?[a-z]*$/;

type Arguments = {
  type: PlanType;
};

export type FeatureByPlan = {
  name: string;
  tooltip?: string;
  value?: boolean | string;
};

interface GetFeaturesByPlan {
  ({ type }: Arguments): FeatureByPlan[];
}

export const data = [
  {
    feature: {
      isSectionHeader: true,
      name: 'Platform and data access',
    },
  },
  {
    feature: {
      name: 'SDKs for server, client, & mobile',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Open source, lightweight, and agentless SDKs for all major languages and frameworks.',
    },
  },
  {
    feature: {
      name: 'Unlimited projects',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: 'Custom',
      tooltip:
        'A project in Rollbar typically maps to a single deployable app/microservice or code repo.',
    },
  },
  {
    feature: {
      name: 'Unlimited users',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: 'Custom',
      tooltip: 'Everyone in your team should have access to Rollbar.',
    },
  },
  {
    feature: {
      name: 'REST API + Terraform provider',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'You can use Rollbar via REST API, Terraform provider alongside or instead of the web UI.',
    },
  },
  {
    feature: {
      name: 'Included event volume',
      free: '5,000 / month',
      essentials: '25K - 50M / month',
      advanced: '25K - 50M / month',
      enterprise: 'Custom',
      tooltip:
        'The number of error, warning, info and debug events included each month.',
    },
  },
  {
    feature: {
      name: 'Maximum data retention',
      free: '30 days',
      essentials: '90 days',
      advanced: '180 days',
      enterprise: 'Custom',
      tooltip:
        "We don't sample your data. Your full error data is securely stored with us.",
    },
  },
  {
    feature: {
      name: 'Customizable data retention',
      free: false,
      essentials: true,
      advanced: true,
      enterprise: 'Custom',
      tooltip:
        'Set a custom retention period for your Rollbar data to any length between 7 to 180 days.',
    },
  },
  {
    feature: {
      name: 'On-demand events',
      free: false,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'On a paid plan, option to continue to capture error, warning, debug and info events beyond your included event volume.',
    },
  },
  {
    feature: {
      name: 'Customizable rate limits',
      free: false,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip: 'Set granular rate limits to keep costs in check.',
    },
  },
  {
    feature: {
      name: 'Rollbar "Analyze": RQL + Metrics API',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: 'Add-on',
      tooltip:
        'You can add-on Rollbar "Analyze": RQL + Metrics API. *Additional Cost Applies',
    },
  },
  {
    feature: {
      isSectionHeader: true,
      name: 'Monitoring and alerting',
    },
  },
  {
    feature: {
      name: 'Account dashboard',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip: 'See a consolidated view of all projects in your account.',
    },
  },
  {
    feature: {
      name: 'Real-time error feed',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'A real-time feed of errors for your project that comes with powerful search and filters.',
    },
  },
  {
    feature: {
      name: 'Intelligent error grouping',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Rollbar groups similar errors automatically to reduce noise, using our ML-powered grouping engine. Add your own custom rules to make it perfect.',
    },
  },
  {
    feature: {
      name: 'Real-time alerts via Slack, email, webhook, & more',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        "Get instant notifications when errors occur in collaboration tools you're using.",
    },
  },
  {
    feature: {
      name: 'Real-time alerts via on-call tools like PagerDuty, VictorOps, OpsGenie',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Get instant notifications when errors occur in on-call incident management tools.',
    },
  },
  {
    feature: {
      name: 'Forward Rollbar data to Datadog',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip: 'Syndicate Rollbar data to Datadog for centralized visibility.',
    },
  },
  {
    feature: {
      name: 'Service Links Per Project',
      free: '1',
      essentials: '1',
      advanced: '5',
      enterprise: 'Unlimited',
      tooltip:
        'Service links allow you to link out to external data related to the error data sent to Rollbar.',
    },
  },
  {
    feature: {
      name: 'Snooze Items',
      free: false,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip: 'Snooze items for set period to avoid alerts.',
    },
  },
  {
    feature: {
      name: 'Adaptive Alerts',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: true,
      tooltip:
        'Reliable, informative, and actionable notifications about unexpected issues in monitored applications and services. Adaptive Alerts notify you about atypical exception rates.',
    },
  },
  {
    feature: {
      name: 'Saved Views',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: true,
      tooltip: 'Save predefined filters as a view on the Item list.',
    },
  },
  {
    feature: {
      name: 'Enhanced account dashboard',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: true,
      tooltip:
        'The enhanced dashboard includes deploy markers to help you correlate deploys to errors.',
    },
  },
  {
    feature: {
      name: 'Cross-project error feed',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: true,
      tooltip:
        'A real-time feed of errors from all your projects (apps/services) on a single screen.',
    },
  },
  {
    feature: {
      isSectionHeader: true,
      name: 'Impact analysis & issue tracking',
    },
  },
  {
    feature: {
      name: 'Contextual "metadata": impacted users, regions, browsers, & more',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip: 'All the data you need to understand the impact of an error.',
    },
  },
  {
    feature: {
      name: 'Watch and assign errors',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Triage an error directly in "Rollbar": assign it to a developer and get status updates.',
    },
  },
  {
    feature: {
      name: 'Auto-create issue tickets in Jira, Asana, Pivotal Tracker, & more',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip: 'Automatically create an issue ticket in issue tracking tools.',
    },
  },
  {
    feature: {
      name: 'Auto-resolve tickets when the error is resolved in Rollbar',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Automatically resolve an issue ticket when the error is marked resolved in Rollbar.',
    },
  },
  {
    feature: {
      name: 'Resolve errors via commit',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Use Git commit messages to automatically resolve errors in Rollbar when you deploy the commit.',
    },
  },
  {
    feature: {
      name: 'Rollbar "Analyze": RQL + Metrics API for custom data analysis',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: 'Add-on',
      tooltip:
        'Use a rich SQL-like interface to perform data analysis and build custom reports.',
    },
  },
  {
    feature: {
      name: 'Team Assignments',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: true,
      tooltip: 'Assign Items to a Individual or Team',
    },
  },
  {
    feature: {
      isSectionHeader: true,
      name: 'Data and context for debugging',
    },
  },
  {
    feature: {
      name: 'Stack traces, request and custom params, local var values, & more',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Rollbar captures and organizes all the detailed data you need to debug an error.',
    },
  },
  {
    feature: {
      name: 'Telemetry',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        "Telemetry shows you a timeline of all the events that happened leading to the error. It's like a black box recorder, but for errors.",
    },
  },
  {
    feature: {
      name: 'Source maps, symbolication, and ProGuard support',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Rollbar works with minified JavaScript, iOS dSYMs, and Android/Java ProGuard.',
    },
  },
  {
    feature: {
      name: 'View source code via GitHub, GitLab, and Bitbucket integration',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Get links to the offending line of code directly inside the stack trace.',
    },
  },
  {
    feature: {
      name: 'Track deploys via integration with Jenkins, CircleCI, & more',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Notify Rollbar every time you deploy to get deploy-related metadata on errors.',
    },
  },
  {
    feature: {
      name: '"Versions": track the impact of all deployed code versions',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'See new and reactivated errors across all projects, by deployed code version. Great for frequent deployments',
    },
  },
  {
    feature: {
      name: 'Similar and co-occurring errors',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip: 'See similar and co-occurring errors for any particular error.',
    },
  },
  {
    feature: {
      isSectionHeader: true,
      name: 'Security & administration',
    },
  },
  {
    feature: {
      name: 'Two-factor authentication',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'Disk-based encryption at rest',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'All your data is protected by encryption both at rest and in transit.',
    },
  },
  {
    feature: {
      name: 'GDPR compliance',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'Basic SSO via Google and GitHub',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Allow users to login to Rollbar using Google or GitHub credentials.',
    },
  },
  {
    feature: {
      name: 'SAML SSO via Okta, Azure, and more',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'SCIM User provisioning',
      free: false,
      essentials: false,
      advanced: true,
      enterprise: true,
      tooltip:
        'Manage and provision Rollbar users directly from Okta, OneLogin, Azure AD, and more',
    },
  },
  {
    feature: {
      name: 'Standard BAA for HIPAA',
      free: false,
      essentials: false,
      advanced: 'Add-on',
      enterprise: true,
      tooltip:
        'Rollbar is HIPAA-compliant and will sign a Business Associate Agreement with you.',
    },
  },
  {
    feature: {
      name: 'Enterprise security controls',
      free: false,
      essentials: false,
      advanced: false,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'Audit controls',
      free: false,
      essentials: false,
      advanced: false,
      enterprise: true,
    },
  },
  {
    feature: {
      isSectionHeader: true,
      name: 'Support & services',
    },
  },
  {
    feature: {
      name: 'Email and chat support',
      free: true,
      essentials: true,
      advanced: true,
      enterprise: true,
      tooltip:
        'Email and chat support for all. We generally respond in 24 hours or less.',
    },
  },
  {
    feature: {
      name: 'Priority support',
      free: false,
      essentials: true,
      advanced: true,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'Custom BAA for HIPAA',
      free: false,
      essentials: false,
      advanced: false,
      enterprise: true,
      tooltip: 'For customers needing a custom BAA.',
    },
  },
  {
    feature: {
      name: 'Top Priority support',
      free: false,
      essentials: false,
      advanced: false,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'Direct Slack channel',
      free: false,
      essentials: false,
      advanced: false,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'Dedicated customer success manager',
      free: false,
      essentials: false,
      advanced: false,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'Onboarding concierge',
      free: false,
      essentials: false,
      advanced: false,
      enterprise: true,
    },
  },
  {
    feature: {
      name: 'Custom SLAs',
      free: false,
      essentials: false,
      advanced: false,
      enterprise: true,
    },
  },
];

type PlanObject = {
  name: string;
  planId: string;
  includedOccurrences: number;
  monthlyCost: number;
  annualCost: number;
  costPerThousand: number;
};

type Plan = {
  plan: PlanObject;
};

type Plans = Plan[];

type PlanDetails = Plan | Plans;

export type PlanType = 'advanced' | 'free' | 'enterprise' | 'essentials';

export type ByIdArguments = {
  id: string;
};

export type ByTypeArguments = {
  type: PlanType;
};

type PlanTypeObject = {
  type: PlanType;
};

export type PlanMap = Record<PlanType, PlanDetails>;

export interface GetPlanById {
  ({ id }: ByIdArguments): (PlanObject & PlanTypeObject) | undefined;
}

export interface GetPlansByType {
  ({ type }: ByTypeArguments): PlanDetails | undefined;
}

export const planData = {
  free: {
    plan: {
      name: 'Free',
      planId: 'free_5k',
      includedOccurrences: 5000,
      monthlyCost: 0,
      annualCost: 0,
      costPerThousand: 0,
    },
  },
  essentials: [
    {
      plan: {
        name: 'Essentials - 25,000/month',
        planId: 'essentials_24_25k',
        includedOccurrences: 25000,
        monthlyCost: 1900,
        annualCost: 19000,
        costPerThousand: 76,
      },
    },
    {
      plan: {
        name: 'Essentials - 50,000/month',
        planId: 'essentials_24_50k',
        includedOccurrences: 50000,
        monthlyCost: 2900,
        annualCost: 29000,
        costPerThousand: 58,
      },
    },
    {
      plan: {
        name: 'Essentials - 100,000/month',
        planId: 'essentials_24_100k',
        includedOccurrences: 100000,
        monthlyCost: 4900,
        annualCost: 49000,
        costPerThousand: 49,
      },
    },
    {
      plan: {
        name: 'Essentials - 200,000/month',
        planId: 'essentials_24_200k',
        includedOccurrences: 200000,
        monthlyCost: 5900,
        annualCost: 59000,
        costPerThousand: 30,
      },
    },
    {
      plan: {
        name: 'Essentials - 300,000/month',
        planId: 'essentials_24_300k',
        includedOccurrences: 300000,
        monthlyCost: 7900,
        annualCost: 79000,
        costPerThousand: 26,
      },
    },
    {
      plan: {
        name: 'Essentials - 400,000/month',
        planId: 'essentials_24_400k',
        includedOccurrences: 400000,
        monthlyCost: 9900,
        annualCost: 99000,
        costPerThousand: 25,
      },
    },
    {
      plan: {
        name: 'Essentials - 500,000/month',
        planId: 'essentials_24_500k',
        includedOccurrences: 500000,
        monthlyCost: 11900,
        annualCost: 119000,
        costPerThousand: 24,
      },
    },
    {
      plan: {
        name: 'Essentials - 1,000,000/month',
        planId: 'essentials_24_1000k',
        includedOccurrences: 1000000,
        monthlyCost: 19900,
        annualCost: 199000,
        costPerThousand: 20,
      },
    },
    {
      plan: {
        name: 'Essentials - 1,500,000/month',
        planId: 'essentials_24_1500k',
        includedOccurrences: 1500000,
        monthlyCost: 29900,
        annualCost: 299000,
        costPerThousand: 20,
      },
    },
    {
      plan: {
        name: 'Essentials - 2,000,000/month',
        planId: 'essentials_24_2000k',
        includedOccurrences: 2000000,
        monthlyCost: 35900,
        annualCost: 359000,
        costPerThousand: 18,
      },
    },
    {
      plan: {
        name: 'Essentials - 3,000,000/month',
        planId: 'essentials_24_3000k',
        includedOccurrences: 3000000,
        monthlyCost: 49900,
        annualCost: 499000,
        costPerThousand: 17,
      },
    },
    {
      plan: {
        name: 'Essentials - 4,000,000/month',
        planId: 'essentials_24_4000k',
        includedOccurrences: 4000000,
        monthlyCost: 65900,
        annualCost: 659000,
        costPerThousand: 16,
      },
    },
    {
      plan: {
        name: 'Essentials - 5,000,000/month',
        planId: 'essentials_24_5M',
        includedOccurrences: 5000000,
        monthlyCost: 79900,
        annualCost: 799000,
        costPerThousand: 16,
      },
    },
    {
      plan: {
        name: 'Essentials - 10,000,000/month',
        planId: 'essentials_24_10M',
        includedOccurrences: 10000000,
        monthlyCost: 159900,
        annualCost: 1599000,
        costPerThousand: 16,
      },
    },
    {
      plan: {
        name: 'Essentials - 15,000,000/month',
        planId: 'essentials_24_15M',
        includedOccurrences: 15000000,
        monthlyCost: 229900,
        annualCost: 2299000,
        costPerThousand: 15,
      },
    },
    {
      plan: {
        name: 'Essentials - 20,000,000/month',
        planId: 'essentials_24_20M',
        includedOccurrences: 20000000,
        monthlyCost: 299900,
        annualCost: 2999000,
        costPerThousand: 15,
      },
    },
    {
      plan: {
        name: 'Essentials - 25,000,000/month',
        planId: 'essentials_24_25M',
        includedOccurrences: 25000000,
        monthlyCost: 359900,
        annualCost: 3599000,
        costPerThousand: 14,
      },
    },
    {
      plan: {
        name: 'Essentials - 30,000,000/month',
        planId: 'essentials_24_30M',
        includedOccurrences: 30000000,
        monthlyCost: 429900,
        annualCost: 4299000,
        costPerThousand: 14,
      },
    },
    {
      plan: {
        name: 'Essentials - 35,000,000/month',
        planId: 'essentials_24_35M',
        includedOccurrences: 35000000,
        monthlyCost: 489900,
        annualCost: 4899000,
        costPerThousand: 14,
      },
    },
    {
      plan: {
        name: 'Essentials - 40,000,000/month',
        planId: 'essentials_24_40M',
        includedOccurrences: 40000000,
        monthlyCost: 549900,
        annualCost: 5499000,
        costPerThousand: 14,
      },
    },
    {
      plan: {
        name: 'Essentials - 45,000,000/month',
        planId: 'essentials_24_45M',
        includedOccurrences: 45000000,
        monthlyCost: 599900,
        annualCost: 5999000,
        costPerThousand: 13,
      },
    },
    {
      plan: {
        name: 'Essentials - 50,000,000/month',
        planId: 'essentials_24_50M',
        includedOccurrences: 50000000,
        monthlyCost: 649900,
        annualCost: 6499000,
        costPerThousand: 13,
      },
    },
  ],
  advanced: [
    {
      plan: {
        name: 'Advanced - 25,000/month',
        planId: 'advanced_24_25k',
        includedOccurrences: 25000,
        monthlyCost: 3900,
        annualCost: 39000,
        costPerThousand: 156,
      },
    },
    {
      plan: {
        name: 'Advanced - 50,000/month',
        planId: 'advanced_24_50k',
        includedOccurrences: 50000,
        monthlyCost: 7900,
        annualCost: 79000,
        costPerThousand: 158,
      },
    },
    {
      plan: {
        name: 'Advanced - 100,000/month',
        planId: 'advanced_24_100k',
        includedOccurrences: 100000,
        monthlyCost: 12900,
        annualCost: 129000,
        costPerThousand: 129,
      },
    },
    {
      plan: {
        name: 'Advanced - 200,000/month',
        planId: 'advanced_24_200k',
        includedOccurrences: 200000,
        monthlyCost: 17900,
        annualCost: 179000,
        costPerThousand: 90,
      },
    },
    {
      plan: {
        name: 'Advanced - 300,000/month',
        planId: 'advanced_24_300k',
        includedOccurrences: 300000,
        monthlyCost: 22900,
        annualCost: 229000,
        costPerThousand: 76,
      },
    },
    {
      plan: {
        name: 'Advanced - 400,000/month',
        planId: 'advanced_24_400k',
        includedOccurrences: 400000,
        monthlyCost: 27900,
        annualCost: 279000,
        costPerThousand: 70,
      },
    },
    {
      plan: {
        name: 'Advanced - 500,000/month',
        planId: 'advanced_24_500k',
        includedOccurrences: 500000,
        monthlyCost: 33900,
        annualCost: 339000,
        costPerThousand: 68,
      },
    },
    {
      plan: {
        name: 'Advanced - 1,000,000/month',
        planId: 'advanced_24_1000k',
        includedOccurrences: 1000000,
        monthlyCost: 50900,
        annualCost: 509000,
        costPerThousand: 51,
      },
    },
    {
      plan: {
        name: 'Advanced - 1,500,000/month',
        planId: 'advanced_24_1500k',
        includedOccurrences: 1500000,
        monthlyCost: 67900,
        annualCost: 679000,
        costPerThousand: 45,
      },
    },
    {
      plan: {
        name: 'Advanced - 2,000,000/month',
        planId: 'advanced_24_2000k',
        includedOccurrences: 2000000,
        monthlyCost: 84900,
        annualCost: 849000,
        costPerThousand: 42,
      },
    },
    {
      plan: {
        name: 'Advanced - 3,000,000/month',
        planId: 'advanced_24_3000k',
        includedOccurrences: 3000000,
        monthlyCost: 114900,
        annualCost: 1149000,
        costPerThousand: 38,
      },
    },
    {
      plan: {
        name: 'Advanced - 4,000,000/month',
        planId: 'advanced_24_4000k',
        includedOccurrences: 4000000,
        monthlyCost: 144900,
        annualCost: 1449000,
        costPerThousand: 36,
      },
    },
    {
      plan: {
        name: 'Advanced - 5,000,000/month',
        planId: 'advanced_24_5M',
        includedOccurrences: 5000000,
        monthlyCost: 174900,
        annualCost: 1749000,
        costPerThousand: 35,
      },
    },
    {
      plan: {
        name: 'Advanced - 10,000,000/month',
        planId: 'advanced_24_10M',
        includedOccurrences: 10000000,
        monthlyCost: 344900,
        annualCost: 3449000,
        costPerThousand: 34,
      },
    },
    {
      plan: {
        name: 'Advanced - 15,000,000/month',
        planId: 'advanced_24_15M',
        includedOccurrences: 15000000,
        monthlyCost: 474900,
        annualCost: 4749000,
        costPerThousand: 32,
      },
    },
    {
      plan: {
        name: 'Advanced - 20,000,000/month',
        planId: 'advanced_24_20M',
        includedOccurrences: 20000000,
        monthlyCost: 594900,
        annualCost: 5949000,
        costPerThousand: 30,
      },
    },
    {
      plan: {
        name: 'Advanced - 25,000,000/month',
        planId: 'advanced_24_25M',
        includedOccurrences: 25000000,
        monthlyCost: 694900,
        annualCost: 6949000,
        costPerThousand: 28,
      },
    },
    {
      plan: {
        name: 'Advanced - 30,000,000/month',
        planId: 'advanced_24_30M',
        includedOccurrences: 30000000,
        monthlyCost: 804900,
        annualCost: 8049000,
        costPerThousand: 27,
      },
    },
    {
      plan: {
        name: 'Advanced - 35,000,000/month',
        planId: 'advanced_24_35M',
        includedOccurrences: 35000000,
        monthlyCost: 904900,
        annualCost: 9049000,
        costPerThousand: 26,
      },
    },
    {
      plan: {
        name: 'Advanced - 40,000,000/month',
        planId: 'advanced_24_40M',
        includedOccurrences: 40000000,
        monthlyCost: 1004900,
        annualCost: 10049000,
        costPerThousand: 25,
      },
    },
    {
      plan: {
        name: 'Essentials - 45,000,000/month',
        planId: 'advanced_24_45M',
        includedOccurrences: 45000000,
        monthlyCost: 1104900,
        annualCost: 11049000,
        costPerThousand: 25,
      },
    },
    {
      plan: {
        name: 'Advanced - 50,000,000/month',
        planId: 'advanced_24_50M',
        includedOccurrences: 50000000,
        monthlyCost: 1204900,
        annualCost: 12049000,
        costPerThousand: 24,
      },
    },
  ],
  enterprise: {
    plan: {
      name: 'Enterprise',
      planId: 'enterprise',
      includedOccurrences: 25000,
      monthlyCost: 0,
      annualCost: 0,
      costPerThousand: 0,
    },
  },
};

export const getPlanById: GetPlanById = ({ id }) => {
  for (const [planType, plans] of Object.entries(planData)) {
    if (Array.isArray(plans)) {
      for (
        let index = 0, planCount = plans.length;
        index < planCount;
        index++
      ) {
        const { plan } = plans[index];

        if (plan.planId === id) {
          return {
            ...plan,
            type: planType as PlanType,
          };
        }
      }
    } else {
      const { plan } = plans;

      if (plan.planId === id) {
        return {
          ...plan,
          type: planType as PlanType,
        };
      }
    }
  }
};

export const getPlansByType: GetPlansByType = ({ type }: ByTypeArguments) =>
  planData[type];

export const getFeaturesByPlan: GetFeaturesByPlan = ({ type }) =>
  data.reduce((accumulator, { feature }) => {
    if (feature.isSectionHeader) {
      return accumulator;
    }

    const { name, tooltip } = feature;

    accumulator.push({
      name,
      tooltip,
      value: feature[type],
    });

    return accumulator;
  }, [] as FeatureByPlan[]);

export const getRelativeDate = (
  relativeDate: string,
  roundRelativeDate: boolean = false
): number => {
  let date: Dayjs | number = dayjs();
  switch (relativeDate) {
    case 'now - 30m':
      date = dayjs().subtract(30, 'm');
      break;
    case 'now - 1h':
      date = dayjs().subtract(1, 'h');
      break;
    case 'now - 24h':
      date = dayjs().subtract(24, 'h');
      break;
    case 'now - 7d':
      date = dayjs().subtract(7, 'd');
      break;
    case 'now - 30d':
      date = dayjs().subtract(30, 'd');
      break;
    default:
      date = Number(relativeDate);
  }

  if (typeof date === 'number') return date;

  if (roundRelativeDate) {
    return date.startOf('h').valueOf();
  } else {
    return date.valueOf();
  }
};

export const getRelativeDateRangeLabel = (relativeDateRange: string) => {
  switch (relativeDateRange) {
    case 'now - 30m':
      return 'Last 30 minutes';
    case 'now - 1h':
      return 'Last hour';
    case 'now - 24h':
      return 'Last 24 hours';
    case 'now - 7d':
      return 'Last 7 days';
    case 'now - 30d':
      return 'Last 30 days';
  }
};

export const shortenRevision = (revision: string | number | null): string => {
  if (!revision) return '';

  revision = String(revision);
  if (revision.match(SHA_REGEX)) {
    return revision.substring(0, 7);
  }

  return revision;
};

export const getIsMobile = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  if (!window.matchMedia) {
    return false;
  }
  return window.matchMedia('(max-width: 767px)').matches;
};

export const getIsDesktop = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  if (!window.matchMedia) {
    return false;
  }
  return !window.matchMedia('(max-width: 1023px)').matches;
};

export const getIsAppleMobile = () => {
  return (
    /iPhone|iPad/.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Macintosh') && navigator.maxTouchPoints)
  );
};
