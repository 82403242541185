import * as React from 'react';

import NotificationContextProvider from '@/contexts/NotificationContext';
import ToastsContextProvider from '@/rollbar-ui/contexts/ToastsContext';

import * as Sidebar from './Sidebar';

type AppShellWithMobileSupportProps = {
  children: React.ReactNode;
};

export default function AppShellWithMobileSupport({
  children,
}: AppShellWithMobileSupportProps) {
  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Sidebar.Desktop className="hidden md:flex" />

      <div className="flex flex-col w-0 flex-1">
        <main className="flex-1 relative focus:outline-none">
          <ToastsContextProvider>
            <NotificationContextProvider>
              {children}
            </NotificationContextProvider>
          </ToastsContextProvider>
        </main>
      </div>
    </div>
  );
}
