import { ArrowUpRightIcon } from '@heroicons/react/24/solid';

import Button from '@/rollbar-ui/Button';
import Image from '@/rollbar-ui/Image';

const FeatureSpotlightCard = ({ className = '' }) => (
  <div
    className={`${className} flex flex-col gap-4 border-2 border-gray-200 p-6 rounded-lg`}
  >
    <div className="grid grid-cols-[256px_1fr] gap-x-6">
      <Image
        alt="Snooze animation"
        className="w-full"
        src="/images/snooze/snooze-animation.gif"
      />

      <div className="flex flex-col gap-4">
        <h2 className="font-bold text-gray-700 text-2xl">Snooze an item</h2>

        <p className="text-gray-500 text-sm">
          Snoozing helps you manage your items by stopping notifications, and
          continuing to process occurrences.
        </p>

        <Button
          className={`${className} !justify-start !p-0 text-red-700 hover:text-red-500 font-normal text-sm underline`}
          variant="link"
        >
          Learn More
          <ArrowUpRightIcon className="h-4" />
        </Button>
      </div>
    </div>
  </div>
);

export default FeatureSpotlightCard;
